import React from "react"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import PageTitle from "../../components/page-title"
import Constrain from "../../components/constrain"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import AudioPlayer from "../../components/audio-player"

const Katalog7 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Stefan George: Hyperion" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Stefan George: Hyperion",
                link: "/k/7",
              },
            ]}
          />
          <PageTitle>Stefan George: Hyperion</PageTitle>
          <Constrain>
            <AudioPlayer src="katalog/07-stefan-george-hyperion" />
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog7
